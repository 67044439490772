import ButterToast from "butter-toast"
import React from "react"
import Notification from "../components/notification"
import { rpc } from "../services/node"
import { LikeType, User } from "../types"

const markThankuMessages = [
  "Thank you for improving crowdsourced filter!",
  "Thank you for improving crowdsourced filter!\nYou are awesome!",
  "You helped a lot!\n",
  "Amazing! Thank you!\n",
  "Cool! Thanks.",
  "You've just made the world better!",
  "Thank you!",
]

export const report = (
  id: string,
  group: string | undefined,
  contentType: LikeType,
  author: string,
  bit: number,
  pro: boolean,
  user: User,
  secret: string,
  setBalance: (amount: number) => void
) => {
  id = contentType == LikeType.Commentator ? "." + id : id
  const msg = {
    id: id,
    node_id: group,
    flag: bit,
    pro: pro,
  }

  rpc("report", JSON.stringify(msg), user, secret, function (res) {
    if (res.status == "ok") {
      setBalance(-0.01 * user.displayed_currency_rate)
      const message =
        markThankuMessages[
          Math.floor(Math.random() * markThankuMessages.length)
        ]
      import(
        "../images/toast/" + (Math.floor(Math.random() * 8) + 1) + ".png"
      ).then(image => {
        const c = React.createElement(
          Notification,
          {
            image: image.default,
            creator: author,
            text: message,
          },
          null
        )

        ButterToast.raise({
          content: c,
          timeout: 3000,
        })
      })
    } else {
      console.error("Reporting failed")
    }
  })
}
