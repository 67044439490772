import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useCallback, useEffect } from "react"
import { FunctionComponent, useState } from "react"
import { getCachedSecret, useUser } from "../hooks/use-user"
import { prettyAmount } from "../services/number"
import { faCheckSquare } from "@fortawesome/pro-regular-svg-icons"
import HtmlTooltip from "./widgets/html-tooltip"
import { rpc } from "../services/node"
import ButterToast from "butter-toast"
import Notification from "../components/notification"
import { useFilters } from "../hooks/use-filters"
import { MuteType } from "../types"

type Props = {
  id: string
  group?: string
  mf: number
  mfg?: number
  btags: string[] | undefined
  flag_stats: any[] | undefined
  onMute: (id: string, kind: MuteType) => void
}

const ModerationFee: FunctionComponent<Props> = ({
  id,
  group,
  mf,
  mfg,
  btags,
  flag_stats,
  onMute,
}) => {
  const [fee, setFee] = useState("")
  const { user } = useUser()
  const { filters } = useFilters()

  useEffect(() => {
    if (user) {
      const fee =
        mfg != undefined
          ? prettyAmount(user.displayed_currency_rate * mf) +
            " (" +
            prettyAmount(user.displayed_currency_rate * mfg) +
            ")"
          : prettyAmount(user?.displayed_currency_rate * mf)
      setFee(fee)
      console.log("fee " + user.displayed_currency_rate + " - " + mf)
    }
  }, [user, fee, mf, mfg])

  const acceptAll = useCallback(() => {
    console.log("acceptAll " + flag_stats)

    /*
    let set_flags = []
    let unset_flags = []
    if (flag_stats) {
      for (const [f, s] of Object.entries(flag_stats)) {
        const flag = parseInt(f)
        if (s.m == 0) {
          console.log(flag)
          if (s.p > s.c) set_flags.push(flag)
          else unset_flags.push(flag)
        } else if (s.m == 1) {
        } else if (s.m == -1) {
        } else {
          const signum = Math.sign(s.p - s.c)
          if (signum > 0) {
            //item.red_flags.push(flag)
          } else {
            //item.green_flags.push(flag)
          }
        }
      }
    }
    */

    let accept_flags = []
    if (flag_stats) {
      for (const [f, s] of Object.entries(flag_stats)) {
        const flag = parseInt(f)
        if (s.m == 0) {
          //console.log(flag)
          if (s.p > s.c) accept_flags.push(flag)
          //else unset_flags.push(flag)
        }
      }
    }

    if (filters.mod == 1) {
      send(
        "accept_moderation",
        JSON.stringify({
          id: id,
          node_id: group,
          tags: btags ? btags.join(",") : undefined,
          flags: accept_flags,
        })
      )
    } else if (filters.mod == 2) {
      send(
        "review_appeal",
        JSON.stringify({
          id: id,
          node_id: group,
        })
      )
    }

    onMute(id, MuteType.Item)
  }, [filters, id, flag_stats, btags, group])

  const send = useCallback(
    (cmd: string, params: string) => {
      rpc(cmd, params, user, getCachedSecret(), function (res) {
        if (res.status == "ok") {
          //if (user) updateBalance(-0.01 * user.displayed_currency_rate)
          const message = "Moderation confirmed"
          import(
            "../images/toast/" + (Math.floor(Math.random() * 8) + 1) + ".png"
          ).then(image => {
            const c = React.createElement(
              Notification,
              {
                image: image.default,
                creator: "",
                text: message,
              },
              null
            )

            ButterToast.raise({
              content: c,
              timeout: 3000,
            })
          })
        } else {
          console.error("Moderation failed")
        }
      })
    },
    [user]
  )

  return (
    <div>
      <div className="fee">
        <HtmlTooltip title="Accept all" placement="top">
          <div className={"action"}>
            <FontAwesomeIcon icon={faCheckSquare} onClick={acceptAll} />
          </div>
        </HtmlTooltip>
        <div style={{ margin: "auto" }}>
          {user ? (
            <span
              dangerouslySetInnerHTML={{
                __html: user.displayed_currency_symbol,
              }}
            />
          ) : (
            <>&#36;</>
          )}{" "}
          {fee}
        </div>
      </div>

      <style jsx>{`
        .fee {
          font-family: "Roboto";
          font-size: 14px;
          padding-left: 30px;
          padding-top: 5px;
          display: inline-flex;
        }

        .action {
          /*padding-left: 25px;*/
          padding-right: 25px;
          text-align: left;
          line-height: 28px;
          opacity: 0.7;
          width: 25px;
          color: rgb(129, 129, 129);
          font-size: 16px;
        }
        .action:hover {
          cursor: pointer;
          color: blue;
        }
      `}</style>
    </div>
  )
}

export default ModerationFee
