import { FunctionComponent } from "react"
import { Channel } from "../../types"
import Avatar from "../layout/avatar"
import { Link } from "@reach/router"
import React from "react"

export type Props = {
  channel: Channel
  className?: string
}

const CommentLabel: FunctionComponent<Props> = ({ channel, className }) => {
  return (
    <Link to={`/channel/${channel.url}`} style={{ textDecoration: "none" }}>
      <div className="link">
        <Avatar
          image={channel.image ?? undefined}
          label={channel.title}
          size={28}
        />

        <strong>{channel.title}</strong>
        <span className="handle">@{channel.name}</span>

        <style jsx>{`
          .handle {
            color: rgb(101, 119, 134);
            margin-left: 4px;
          }
          .link {
            min-width: 50px;
            display: flex;
            align-items: center;
            text-decoration: none;
            font-family: Roboto;
            font-size: 14px;
          }
          .link:hover strong {
            text-decoration: underline;
          }
          strong {
            margin-left: 0.5em;
            font-size: 14px;
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            flex: 1;
            display: block;
          }
        `}</style>
      </div>
    </Link>
  )
}

export default CommentLabel
