import { forwardRef, DetailedHTMLProps, HTMLAttributes } from "react"
import Linkify from "react-linkify"
import { lightTheme, darkTheme } from "../../services/theme"
import { useStyle } from "../../hooks/use-style"
import React from "react"

type Props = DetailedHTMLProps<
  HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
> & {
  linkify?: boolean
}

const Description = forwardRef<HTMLParagraphElement, Props>(
  ({ children, linkify = false }, ref) => {
    const css = useStyle(theme => ({
      textColor:
        theme === "light"
          ? lightTheme.color.text.primary
          : darkTheme.color.text.primary,
      linkColor: theme === "light" ? "inherit" : darkTheme.color.text.primary,
    }))

    return (
      <div
        ref={ref}
        // TODO: lang (for hypenation)
      >
        {linkify ? (
          <Linkify
            options={{
              attributes: {
                style: {
                  position: "relative",
                  zIndex: 1,
                  color: css.linkColor,
                  transition: "200ms color",
                } as any,
              },
            }}
          >
            {children}
          </Linkify>
        ) : (
          children
        )}

        <style jsx>{`
          div {
            color: ${css.textColor};
            transition: 200ms color;
            word-break: break-word;
            hyphens: auto;
            /*font-weight: lighter;*/
            font-weight: 400;
            font-size: 14px;
            font-family: Roboto;
            white-space: pre-line;
          }
        `}</style>
      </div>
    )
  }
)

export default Description
