import { FunctionComponent, ReactNode } from "react"
import React from "react"

export type Props = {
  url: string
  children: {
    header: ReactNode
    content: ReactNode
    upperToolbar: ReactNode
    lowerToolbar: ReactNode
    footer?: ReactNode
  }
}

const Panel: FunctionComponent<Props> = ({ url, children }) => (
  <div className="panel">
    <a href={url} target="_blank"></a>
    <div className="top row">
      <div className="row expanding">{children.header}</div>
      <div className="row fixed">{children.upperToolbar}</div>
    </div>

    <div>{children.content}</div>

    <div className="bottom row">
      {children.footer && children.footer}
      <div className="row options">{children.lowerToolbar}</div>
    </div>

    <style jsx>{`
      .panel {
        width: 100%;
      }
      .panel > *:not(:first-child) {
        /*margin-top: 1em;*/
      }
      .row {
        display: flex;
        min-width: 0;
        align-items: center;
      }
      .row > :global(*:not(:last-child)) {
        /*margin-left: 1em;*/
      }
      .column {
        display: flex;
        flex-direction: column;
        min-width: 0;
      }
      .column > :global(*:not(:last-child)) {
        margin-top: 1em;
      }
      .top {
        justify-content: space-between;
        align-items: flex-start;
      }
      .bottom {
        justify-content: space-between;
        align-items: center;
      }
      .expanding {
        flex-shrink: 1;
      }
      .fixed {
        flex-shrink: 0;
      }
      .options {
      }
      @media (hover: hover) and (pointer: fine) {
        .options {
          visibility: hidden;
        }
        .panel:hover .options {
          visibility: visible;
        }
      }
    `}</style>
  </div>
)

export default Panel
