import { FunctionComponent, useCallback } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faAd,
  faBan,
  faAngry,
  faCopyright,
  faExclamationTriangle,
  faFlag,
  faHandPaper,
  faSwords,
  faTrashAlt,
  faVenusMars,
  faQuestionSquare,
  faPrayingHands,
  faUsdSquare,
  faUnlink,
  faEye,
  faHandMiddleFinger,
  faImage,
  faUser,
} from "@fortawesome/pro-light-svg-icons"
import { lightTheme, darkTheme } from "../../services/theme"
import { useStyle } from "../../hooks/use-style"
import React from "react"
import { Tooltip, withStyles, makeStyles } from "@material-ui/core"

const useStylesBootstrap = makeStyles(theme => ({
  tooltip: {
    backgroundColor: "#ffffff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 250,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))

function FlagActions(props) {
  const classes = useStylesBootstrap()
  return (
    <Tooltip
      arrow
      classes={classes}
      {...props}
      enterTouchDelay={1}
      leaveTouchDelay={3000}
    />
  )
}

/*
const FlagActions = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#ffffff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 250,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip)
*/

const flag_meta = [
  [faExclamationTriangle, "Fake"],
  [faVenusMars, "Adult content"],
  [faAd, "Promotional"],
  [faFlag, "Gov't sponsored"],
  [faSwords, "Violent"],
  [faTrashAlt, "Spam"],
  [faPrayingHands, "Religious offense"],
  [faHandPaper, "Fake likes"],
  [faAngry, "Offensive"],
  [faUnlink, "Broken link"],
  [faBan, "Illegal"],
  [faEye, "Security threat"],
  [faUsdSquare, "Paywall"],
  [faCopyright, "Copyright violation"],
  [faImage, "Bad metadata"],
  [faHandMiddleFinger, "Obscene language"],
  [faUser, "Registration wall"],
]

const getFlagMeta = (flag: number) => {
  const meta = flag_meta[flag]
  if (meta) return meta
  else return [faQuestionSquare, "Unknown"]
}

type Props = {
  kind: number
  stats: any
  onChange?: (flag: number, pro: boolean) => void
}

const Flag: FunctionComponent<Props> = ({ kind, stats, onChange }) => {
  const css = useStyle(theme => ({
    textColor:
      theme === "light"
        ? lightTheme.color.text.secondary
        : darkTheme.color.text.secondary,
    activeTextColor:
      theme === "light"
        ? lightTheme.color.text.primary
        : darkTheme.color.text.primary,
  }))

  const meta = getFlagMeta(kind)

  const markContent = useCallback(
    (pro: boolean) => {
      if (onChange) onChange(kind, pro)
    },
    [kind]
  )

  const disputeMarker =
    stats != undefined && stats[kind] != undefined && stats[kind].d
      ? "thumbsUpContainer disputed"
      : "thumbsUpContainer"

  return (
    <div className={disputeMarker}>
      <FlagActions
        title={
          <React.Fragment>
            <div style={{ fontSize: "12px", fontWeight: 400, padding: "10px" }}>
              <div style={{ textAlign: "center", marginBottom: "10px" }}>
                <div style={{}}>{meta[1]}?</div>
              </div>
              <table>
                <tbody>
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      {stats != undefined &&
                        stats[kind] != undefined &&
                        stats[kind].p}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {stats != undefined &&
                        stats[kind] != undefined &&
                        stats[kind].c}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <button
                        className="btn"
                        onClick={() => {
                          markContent(true)
                        }}
                      >
                        Yes
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn"
                        onClick={() => {
                          markContent(false)
                        }}
                      >
                        No
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </React.Fragment>
        }
        placement="top"
        interactive
      >
        <div>
          <FontAwesomeIcon icon={meta[0]} size="lg" className="thumbsUp" />
        </div>
      </FlagActions>

      <style jsx>{`
        .btn {
          width: 80px;
          padding: 5px;
        }

        .thumbsUpContainer {
          color: rgb(129, 129, 129);
          padding-left: 2px;
          padding-right: 2px;
        }
        .thumbsUpContainer:hover {
          cursor: pointer;
          color: blue;
        }

        .thumbsUp {
          z-index: 1;
          margin-top: -3px;
          transform: rotate(1deg);
          fill: rgb(129, 129, 129);
          color: rgba(0, 0, 0, 0);
        }
        .thumbsUp:hover {
          color: blue;
        }
        .disputed {
          border-bottom: solid 2px;
        }
      `}</style>
    </div>
  )
}

export default Flag
